<template>
  <span
      :class="[
        small ? 'text-3xs' : 'text-xs',
        deletable && 'pr-1',
        interactive && 'interactive cursor-pointer',
        disabled && 'disabled',
        `tag--${outline ? colorTag+'-outline' : colorTag }`
      ]"
      @click="handleClick"
      ref="tagColor"
  >
    <span class="tag__text">{{ text }}</span>
    <span v-if="deletable && !disabled" class="flex justify-center items-center w-4 h-4 cursor-pointer" @click.stop="handleDelete">
      <Close2Icon class="delete-icon"/>
    </span>
  </span>
</template>

<script>
import Close2Icon from "@/components/ui/icons/Close2Icon";

export default {
  name: "TagColor",

  components: {Close2Icon},

  props: {
    text: {
      type: String,
      required: true,
    },
    idTag:{
      type: String,
      required: false,
    },
    colorTag: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'purple'].includes(value)
    },
    outline:{
      type: Boolean,
      default: false
    },  
    interactive: {
      type: Boolean,
      default: false,
    },

    deletable: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    small: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['selected','deleteTag'],

  methods: {
    handleClick() {
      if (this.interactive && !this.disabled) {
        this.$emit('selected', this.idTag || this.text);
      }
    },
    handleDelete() {
      if (!this.disabled) {
        this.$emit('deleteTag', this.idTag || this.text);
      }
    }
  },
}
</script>

<style scoped>
.tag__text {
  @apply truncate whitespace-no-wrap min-w-0;
}
.delete-icon {
  @apply w-2 h-2;
  color: inherit;
}

.tag.interactive:hover {
  @apply bg-blue-600 text-white;
}
.tag.interactive:hover .delete-icon {
  @apply text-white;
}

.tag.disabled {
  @apply bg-gray-200 text-gray-400;
}


/*TAG-COLOR*/
.tag--primary {
  @apply inline-flex items-center overflow-hidden mr-1 px-2 py-1 rounded-xs bg-blue-100 text-blue-800 font-frank font-medium;
}

.tag--primary:hover {
  @apply bg-transparent bg-blue-100;
}
.tag--primary-outline{
  @apply text-blue-800 border border-blue-500 font-semibold tracking-wider flex items-center gap-1 px-2 py-1;
  font-size: 0.6875rem;
}

.tag--primary-outline:hover {
  @apply bg-transparent bg-blue-100;
}

.tag--purple {
  @apply bg-purple-350 border border-purple-350 text-white font-semibold tracking-wider flex items-center gap-1 px-2 py-1;
  font-size: 0.6875rem;
}
.tag--purple:hover {
  @apply bg-transparent text-purple-350;
}
.tag--purple:disabled,
.tag--purple:disabled:hover {
  @apply border-graphite-300 bg-graphite-300 text-gray-700 cursor-not-allowed;
}
/*Purple-200*/
.tag--purple-200 {
  @apply bg-purple-100 border border-purple-350 text-purple-500 font-semibold tracking-wider flex items-center gap-1 px-2 py-1;
  font-size: 0.6875rem;
}
.tag--purple-200:hover {
  @apply bg-transparent text-purple-350;
}
.tag--purple-200:disabled,
.tag--purple-200:disabled:hover {
  @apply border-graphite-300 bg-graphite-300 text-gray-700 cursor-not-allowed;
}
.tag--purple-outline{
  @apply text-purple-350 border border-purple-350 font-semibold tracking-wider flex items-center gap-1 px-2 py-1;
  font-size: 0.6875rem;
}
.tag--purple-outline:hover{
  @apply bg-purple-100;
}
</style>