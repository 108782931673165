<template>
  <button :class="`btn--${outline ? colorButton+'-outline' : colorButton}`"><slot>Name Button</slot></button>
</template>
<script>

export default {
  name: "ButtonComponent",
  props: {
    buttonColor:{
      type:String,
      default:'purple',
    },
    outline:{
      type: Boolean,
      default: false
    },
    colorButton: {
      type: String,
      default: 'purple',
      validator: (value) => ['purple', 'outline'].includes(value)
    },
  },
}
</script>
<style scoped>
.btn{
  @apply bg-active-500
}
.btn--purple {
  @apply bg-purple-350 border border-purple-350 text-white font-semibold tracking-wider flex items-center gap-1 px-2 py-1;
  font-size: 0.6875rem;
}
.btn--purple:hover {
  @apply bg-transparent text-purple-350;
}
.btn--purple:disabled,
.btn--purple:disabled:hover {
  @apply border-graphite-300 bg-graphite-300 text-gray-700 cursor-not-allowed;
}
.btn--purple-outline{
  @apply text-purple-350 border border-purple-350 font-semibold tracking-wider flex items-center gap-1 px-2 py-1;
  font-size: 0.6875rem;
}
.btn--purple-outline:hover{
  @apply bg-purple-100;
}
</style>