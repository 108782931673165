<template>
  <div class="relative modal-box">
    <button class="modal-box__action" @click="toggleModal(true)" :disabled="loading"
      :class="{ 'modal-box__action--disabled': loading }">
      <Icon v-if="!!iconName" :name="iconName" class="w-4 h-4" />
      {{ buttonName }}
    </button>
    <div v-if="showModal" ref="modalBoxContent" class="modal-box__content">
      <div class="grid gap-4">
        <div class="py-3 px-4">
          <h2 class="modal-box-title">{{ title }}</h2>
          <div class="mt-5">
            <slot name="body">Add your component or content here!</slot>
          </div>
        </div>
        <div v-if="showCloseBtn || showApplyBtn" class="flex justify-end gap-3 border-t py-3 px-4 border-gray-300">
          <button v-if="showCloseBtn" class="btn-outline" @click="toggleModal(false)">cancel</button>
          <button v-if="showApplyBtn" class="btn-normal" :class="{ 'btn-normal--disabled': disableApply }"
            @click="applyChanges">apply</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "@/components/ui/Icon";
import ButtonComponent from '@/components/report/common/ButtonComponent.vue';

export default {
  name: "ModalBox",

  emits: [
    'showModal',
    'apply'
  ],

  components: {
    Icon,
    ButtonComponent
  },

  props: {
    title: {
      type: String,
      default: 'Filters'
    },
    buttonName: {
      type: String,
      default: 'Filter'
    },
    iconName: {
      type: String,
      default: 'filter'
    },
    showCloseBtn: {
      type: Boolean,
      default: false,
    },
    showApplyBtn: {
      type: Boolean,
      default: false,
    },
    disableApply: {
      type: Boolean,
      default: false,
    },
    /**
     * The modal will be controlled by this value
     * If is true the modal will be visible if is false will be hidden
     */
    showModal: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      toggle: false,
    }
  },

  methods: {
    /**
     * Toggle modal visibility
     * @param {boolean} status 
     * This method will emit a event to the parent component with the showModal update
     * The parent MUST listen this update and change the showModal prop
     */
    toggleModal(status) {
      this.$emit('showModal', status);
    },

    /**
     * Emit apply changes event
     */
    applyChanges() {
      this.$emit('apply');
      this.toggleModal(false);
    },

    handleOutsideClickEvent(e) {
      if (this.loading) {
        return;
      }

      const el = this.$refs.modalBoxContent;

      if (el && !el.contains(e.target) && !e.target.closest('.modal-box__action')) {
        this.toggleModal(false);
      }
    },
  },

  mounted() {
    window.addEventListener('click', this.handleOutsideClickEvent);
  },

  beforeUnmount() {
    window.removeEventListener('click', this.handleOutsideClickEvent);
  },
}
</script>

<style scoped>
.modal-box {
  @apply z-30;
}
.modal-box-title{
  @apply font-frank font-700 text-base;
}
.modal-box .modal-box__content {
  @apply absolute right-0 top-0 bg-white content-center items-center border border-gray-300 rounded-sm shadow-xs z-20;
  min-width: 360px;
}

.modal-box .modal-box__action {
  @apply flex items-center text-purple-350 gap-1;
  font-size: 0.8125rem;
}

.modal-box .modal-box__action:hover {
  @apply text-purple-400;
}

.modal-box .modal-box__action--disabled {
  @apply text-gray-700 cursor-wait;
}

.modal-box .modal-box__action--disabled:hover {
  @apply text-gray-700;
}

.modal-box .btn-outline {
  @apply text-purple-350 border border-purple-350 font-semibold tracking-wider flex items-center gap-1 px-2 py-1;
  font-size: 0.6875rem;
}

.modal-box .btn-outline:hover {
  @apply bg-purple-100;
}

.modal-box .btn-outline--disabled,
.modal-box .btn-outline--disabled:hover {
  @apply border-gray-700 text-gray-700 cursor-not-allowed;
}

.modal-box .btn-normal {
  @apply bg-purple-350 border border-purple-350 text-white font-semibold tracking-wider flex items-center gap-1 px-2 py-1;
  font-size: 0.6875rem;
}

.modal-box .btn-normal:hover {
  @apply bg-transparent text-purple-350;
}

.modal-box .btn-normal--disabled,
.modal-box .btn-normal--disabled:hover {
  @apply border-graphite-300 bg-graphite-300 text-gray-700 cursor-not-allowed;
}
</style>