<template>
  <ul class="items-list mt-5">
    <li v-for="item in itemsToSelect" :key="item.id" @click.stop="handleClick(item)">
      <button>
        <span>{{ item.name }}</span>
      </button>
    </li>
  </ul>
</template>
<script>
export default {
  name:"ListItems",
  props:{
    itemsToSelect:{
      type: Array,
      required: true
    }
  },
  emits:['selected'],

  methods:{
    handleClick(item){
      this.$emit('selected', item);
    }
  },
}
</script>
<style scoped>
.items-list {
  @apply overflow-auto;
  height: 228px;
}
</style>