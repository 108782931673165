<template>
    <ReportBase title="Conversation by Main Topic" :is-loading="loadingContent" class="custom-responses">
        <template #afterTitle>
            <div class="flex items-center ml-auto">
                <ModalBox title="Filters" button-name="filters" :loading="loading" :showModal="showFilterModal"
                    show-apply-btn show-close-btn @apply="handleModalApply" @showModal="handleModalStatus">
                    <template #body>
                        <Loading v-if="loadingListOfTopic" />
                        <template v-else>
                            <FilterListView :showFilter="toggle" @showView="handleView" :itemsToSelect="listOfTopics"
                                :itemsSelected="selectedMainTopics" titleItemSelected="Selected main topics"
                                @updatedItems="filteredMainTopics = $event" />
                        </template>
                    </template>
                </ModalBox>
            </div>
        </template>
        <template #content>
            <TableContent :columnDefs="tableContent.columnDefs" :defaultColDef="tableContent.defaultColDef"
                :rowData="tableContent.rowData" />
        </template>
    </ReportBase>
</template>

<script>
import ReportBase from '@/components/report/tabular/ReportBase';
import ModalBox from "@/components/report/common/ModalBox.vue";
import Dropdown from '@/components/ui/Dropdown';
import Loading from '@/components/report/loading/LoadingComponent.vue';
import TableContent from '@/components/report/tabular/TableContent.vue';
import { responseToTable, getMenuItem } from '@/components/report/tabular/cubeDataHandler';
import FilterListView from "@/components/report/common/ModalFilter/FilterListView.vue";
import NotifyMixin from '@/mixins/NotifyMixin';
import CubeRequestMixin from "@/components/report/common/CubeRequestMixin";

export default {
    name: 'ConversationByMainTopic',

    mixins: [CubeRequestMixin, NotifyMixin],

    data() {
        return {
            loadingContent: true,
            loadingListOfTopic: true,
            showFilterModal: false,
            tableContent: null,
            listOfTopics: [],
            selectedMainTopics: [],
            filteredMainTopics: [],
        }
    },

    components: {
        ReportBase,
        ModalBox,
        Dropdown,
        Loading,
        TableContent,
        FilterListView
    },

    computed: {
        hasTopics() {
            return this.listOfTopics.lenght > 0 ? true : false;
        }
    },

    methods: {
        async fetchTotalResponses(params = {}) {
            this.loadingContent = true;

            try {
                const response = await this.fetchCubeData('DHR07', params);

                this.tableContent = responseToTable(response);

                this.loadingContent = false;
            } catch (error) {
                this.notifyError(error.message);
            }
        },

        async fetchFilterTopics() {
            this.loadingListOfTopic = true;

            try {
                const response = await this.fetchCubeData('DHR07_FILTER');

                this.listOfTopics = getMenuItem(response);

                this.loadingListOfTopic = false;
            } catch (error) {
                this.notifyError(error.message);
            }
        },

        handleModalStatus(status) {
            this.showFilterModal = status;
            if (this.showFilterModal) {
                this.fetchFilterTopics();
            }
            if (!this.showFilterModal) {
                this.filteredMainTopics = [];
            }
        },
        handleFilter(topics) {
            if (topics.length !== 0) {
                const filteredTopicsParams = { filters: topics.map(topic => (topic.name)).join(",") };
                this.fetchTotalResponses(filteredTopicsParams);
            }
        },
        handleModalApply() {
            this.selectedMainTopics = this.filteredMainTopics;
            this.handleFilter(this.selectedMainTopics);
        }
    },

    mounted() {
        this.fetchTotalResponses();
    }
};
</script>
<style scoped>
.wrapper-table {
    @apply w-full h-full flex flex-col;
}

.filter-wrapper {
    min-width: 360px;
}
</style>