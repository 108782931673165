<template>
  <div v-if="showFilter" class="filter-view-items">
    <div class="grid" :class="gridCols" ref="modalView">
      <div class="border-r border-gray-300 mr-4 pr-4" :class="{ hidden: hidden }">
        <h2 class="text-base font-bold mb-2">{{ titleItemToSelect }}</h2>
        <TextInput v-model="search" placeholder="search">
          <template v-if="trailingIcon" v-slot:trailing-icon>
            <Icon class="w-4 h-4" name="search2" />
          </template>
        </TextInput>
        <ListItems :itemsToSelect="itemList" @selected="handleSelected" />
      </div>
      <div class="pt-3">
        <h2 class="text-base font-bold mb-1">{{ titleItemSelected }}</h2>
        <div class="flex flex-wrap gap-2">
          <TagColor v-for="(item) in items" :key="item.id" @selected="handleSelected" :text="item.name" deletable
            @tagView="handleSelected" @deleteTag="handleDelete" colorTag="purple-200" />
        </div>
        <button v-if="items?.length" class="btn-community border-none mt-2" @click.stop="clearSelecteditems">
          <span>clear all</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "@/components/ui/Icon";
import TextInput from '@/components/ui/TextInput';
import ListItems from "@/components/report/common/ModalFilter/ListItems.vue";
import TagColor from '@/components/report/common/TagColor.vue';

export default {
  name: "FilterListView",
  emits: ['showView'],
  components: {
    ListItems,
    TextInput,
    TagColor,
    Icon,
  },
  props: {
    /**
     *The view will be controlled by this value. 
     *If it is true it will be visible, 
     *It is false, it will be hidden.
     */
    showFilter: {
      type: Boolean,
      default: true,
    },
    /**
     * Title of sidebar  
     * @param {string} titleItemToSelect 
     * This param set the title to be shown on the top of the items
     * It's not required
     */
     titleItemToSelect: {
      type: String,
      required:false,
    },
    /**
     * Title Selected Item of sidebar  
     * @param {string} titleItemSelected 
     * This param set the title of the Right side where it's shown the selected items     * It's not required
     */
    titleItemSelected:{
      type: String,
      required:false,
    },
    itemsToSelect: {
      type: Array,
      required: true
    },
    itemsSelected: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      search: "",
      toggle: false,
      items: [],
      hidden: false,
      gridCols:"grid-cols-2",
    }
  },
  computed: {
    itemList() {
      return this.itemsToSelect?.filter((item) => {
        if (this.items.some((i) => i.id === item.id)) {
          return false;
        }
        if (this.search) {
          return item.name
            .toLowerCase()
            .includes(this.search.toLowerCase());
        }
        return true;
      });
    }, 
  },
  watch:{
    items(){
      this.$emit('updatedItems', this.items);
      this.renderHandler();
    },
  },
  methods: {
    /**
     * Toggle modal visibility
     * @param {boolean} status 
     * This method will emit a event to the parent component with the showModal update
     * The parent MUST listen this update and change the showModal prop
     */
    toggleView(status) {
      this.$emit('showView', status);
    },
    handleSelected(value) {
      this.items = [...this.items, value];
    },
    handleDelete(value) {
      this.items = this.items?.filter((item) => item.name !== value);
    },
    clearSelecteditems() {
      this.items = [];
    },
    renderHandler(){
      if(!this.itemList?.length){
        this.hidden = true;
        this.gridCols = "grid-cols-1"
        return;
      }
      this.hidden = false;
      this.gridCols = "grid-cols-2"
    }
  },
  mounted(){
    this.items = this.itemsSelected;
  }
}
</script>
<style scoped>
.filter-view-items {
  min-width: 560px;
}
</style>